$font-family: 'Nunito', sans-serif;
$optional-font-family: 'Montserrat', sans-serif;
$main-color: #4303b8;
$secondary-color: #03bff3;
$optional-color: #ffac00;
$white-color: #ffffff;
$black-color: #0e101b;
$paragraph-color: #666666;
$dark-color: #2d246b;
$transition: 0.5s;
$font-size: 16px;

$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1442px;
$value_eight: 1550px;
