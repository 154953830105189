@import 'variables';

@media only #{$media} and ($feature_max : $value_two) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 13px;
  }
  .btn {
    padding: 14px 25px;
    font-size: 13px;
  }
  .ptb-100 {
    padding: {
      top: 65px;
      bottom: 65px;
    }
  }
  .section-title {
    margin-bottom: 40px;

    span {
      font-size: 15px;
    }
    h2 {
      font-size: 25px;
      line-height: 1.3;
    }
  }

  .top-header-left {
    text-align: center;
  }
  .top-header-right {
    text-align: center;
    margin-top: 10px;

    .social {
      li {
        margin-right: 3px;

        &:last-child {
          margin-right: 0;
        }
        a {
          width: 30px;
          height: 30px;
          line-height: 31px;
          font-size: 12px;
        }
      }
    }
  }
  .header-area {
    &.header-style-two {
      border: {
        bottom: 1px solid #4e4957;
      }
    }
  }

  .main-banner {
    background: {
      image: unset;
      color: $main-color;
    }
    padding: {
      top: 100px;
      bottom: 80px;
    }

    clip-path: none;
    border-bottom-left-radius: 0;
    background: linear-gradient(90deg, #4303b8, #7f1cbd, #49d1f5);
  }
  .main-banner-content {
    text-align: center;
    .more-plans {
      margin-left: auto;
    }
    .sub-title {
      margin-bottom: 10px;
      font-size: 15px;

      i {
        width: 20px;
        position: relative;
        top: -3px;
        height: 20px;
        line-height: 17px;

        &::before {
          font-size: 8px;
        }
      }
    }
    h1 {
      font-size: 38px;
    }
    p {
      font-size: 14px;
    }
    .price {
      margin-top: 10px;
      font-size: 38px;

      span {
        left: -7px;
        font-size: 16px;
      }
    }
    .btn-box {
      margin-top: 10px;

      .video-btn {
        width: 45px;
        height: 45px;
        line-height: 45px;
        top: 4px;
      }
    }
  }
  .banner-image {
    text-align: center;
    margin-top: 50px;

    img {
      max-width: 100%;
    }
  }
  .features-area {
    padding-bottom: 0;
    margin-bottom: -30px;
  }
  .single-features-box {
    padding: 20px;

    .icon {
      margin-bottom: 6px;

      i {
        &::before {
          font-size: 35px;
        }
      }
    }
    h3 {
      font-size: 19px;
    }
  }

  .single-box {
    padding: 20px;

    .icon {
      width: 65px;
      height: 65px;
      margin-bottom: 20px;
      line-height: 66px;

      i {
        &::before {
          font-size: 25px;
        }
      }
    }
    h3 {
      font-size: 19px;
    }
    .details-btn {
      margin-top: 15px;
    }
  }

  .about-content {
    text-align: center;

    span {
      font-size: 15px;
    }
    h2 {
      line-height: 1.3;
      margin-bottom: 12px;
      font-size: 25px;
    }
  }
  .about-image {
    margin-top: 40px;
  }
  .about-main-image {
    margin-top: 35px;
  }
  .services-area {
    padding-bottom: 35px;

    &.bg-image {
      background-image: unset;
      background-color: #262735;
    }
  }
  .single-services-box {
    padding: 20px;

    .icon {
      i::before {
        font-size: 30px;
      }
    }
    h3 {
      font-size: 19px;
    }
  }
  .services-box {
    padding: 20px;

    .icon {
      margin-bottom: 20px;
      width: 70px;
      height: 70px;
      line-height: 70px;

      i {
        &::before {
          font-size: 25px;
        }
      }
    }
    h3 {
      font-size: 19px;
    }
  }

  .pricing-area {
    padding-bottom: 35px;
  }
  .single-pricing-table {
    text-align: center;
    padding: 25px;

    .pricing-header {
      .icon {
        width: 70px;
        height: 70px;
        line-height: 70px;

        i {
          &::before {
            font-size: 25px;
          }
        }
      }
      h3 {
        font-size: 19px;
      }
    }
    .price {
      font-size: 25px;

      span {
        &:nth-child(1) {
          margin-bottom: -3px;
        }
      }
    }
    .view-plans-btn {
      margin-top: 20px;
      font-size: 14px;
    }
  }

  .coverage-area-content {
    text-align: center;

    span {
      font-size: 15px;
    }
    h2 {
      margin-bottom: 12px;
      font-size: 25px;
    }
  }
  .coverage-area-map {
    margin-top: 35px;

    img {
      max-width: 100%;
    }
  }

  .cta-area {
    padding: {
      top: 55px;
      bottom: 55px;
    }
  }
  .cta-content {
    text-align: center;

    h3 {
      font-size: 19px;
      line-height: 1.3;
    }
    a {
      font-size: 25px;
    }
  }
  .cta-btn {
    text-align: center;
    margin-top: 15px;
  }

  .pricing-about-content {
    text-align: center;

    span {
      font-size: 15px;
    }
    h2 {
      font-size: 25px;
      line-height: 1.3;
    }
  }

  .error-area {
    height: 100%;
    padding: {
      top: 150px;
      bottom: 150px;
    }
  }
  .error-content {
    h3 {
      font-size: 28px;
      margin: {
        top: 25px;
        bottom: 10px;
      }
    }
  }

  .pagination-area {
    margin-top: 20px;

    .page-numbers {
      width: 38px;
      height: 38px;
      margin: 0 2px;
      line-height: 38px;
      font-size: 16px;
    }
  }

  .contact-info-box-area {
    padding-top: 35px;
  }
  .single-contact-info {
    padding: 20px;

    .icon {
      width: 70px;
      height: 70px;
      line-height: 70px;

      i {
        &::before {
          font-size: 25px;
        }
      }
    }
    h3 {
      font-size: 19px;
    }
  }

  .footer-area {
    padding-top: 65px;
  }
  .single-footer-widget {
    .logo {
      p {
        font-size: 13px;
        margin-top: 12px;
      }
    }
    h3 {
      margin-bottom: 20px;
      font-size: 19px;
    }
  }
  .copyright-area {
    margin-top: 35px;
    padding: {
      top: 25px;
      bottom: 25px;
    }
  }
  .go-top {
    right: 15px;
    width: 35px;
    height: 35px;
    line-height: 36px;

    &.active {
      bottom: 15px;
    }
  }
}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
  body {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }
  .section-title {
    span {
      font-size: 17px;
    }
    h2 {
      font-size: 32px;
    }
  }
  .ptb-100 {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }

  .header-area {
    .top-header {
      padding: {
        left: 20px;
        right: 20px;
      }
      .container {
        max-width: 100%;
      }
    }
  }
  .navbar-area {
    .vimi-mobile-nav {
      .logo {
        left: 35px;
      }
      &.mean-container {
        a {
          &.meanmenu-reveal {
            padding: 5px 35px 0 0;
          }
        }
      }
    }
    &.is-sticky {
      .vimi-mobile-nav {
        &.mean-container {
          a {
            &.meanmenu-reveal {
              padding: 9px 35px 0 0 !important;
            }
          }
        }
      }
    }
  }

  .main-banner {
    background: {
      image: unset;
      color: $main-color;
    }
    padding: {
      top: 125px;
      bottom: 80px;
    }

    clip-path: none;
    border-bottom-left-radius: 0;
    background: linear-gradient(90deg, #4303b8, #7f1cbd, #49d1f5);
  }
  .main-banner-content {
    .sub-title {
      font-size: 15px;
    }
    h1 {
      font-size: 50px;
    }
    p {
      font-size: 15px;
    }
    .price {
      font-size: 50px;

      span {
        left: -8px;
        font-size: 28px;
      }
    }
  }
  .banner-image {
    text-align: center;
    margin-top: 55px;

    img {
      max-width: 100%;
    }
  }

  .features-area {
    padding-bottom: 0;
    margin-bottom: -30px;
  }
  .single-features-box {
    h3 {
      font-size: 21px;
    }
  }

  .about-content {
    span {
      font-size: 17px;
    }
    h2 {
      font-size: 32px;
    }
  }
  .about-image {
    margin-top: 40px;
  }
  .about-main-image {
    margin-top: 40px;
  }

  .single-box {
    h3 {
      font-size: 22px;
    }
  }

  .services-area {
    padding-bottom: 50px;
  }
  .single-services-box {
    h3 {
      font-size: 22px;
    }
  }
  .services-box {
    h3 {
      font-size: 22px;
    }
  }

  .pricing-area {
    padding-bottom: 50px;
  }
  .single-pricing-table {
    padding: 35px;

    .pricing-header {
      h3 {
        font-size: 22px;
      }
    }
  }

  .cta-area {
    text-align: center;
  }
  .cta-content {
    h3 {
      font-size: 22px;
    }
    a {
      font-size: 32px;
    }
  }
  .cta-btn {
    text-align: center;
    margin-top: 20px;
  }

  .coverage-area-map {
    margin-top: 35px;

    img {
      max-width: 100%;
    }
  }

  .error-area {
    height: 100%;
    padding: {
      top: 200px;
      bottom: 200px;
    }
  }

  .pricing-about-content {
    span {
      font-size: 17px;
    }
    h2 {
      font-size: 32px;
    }
  }

  .contact-info-box-area {
    padding-top: 50px;
  }
  .single-contact-info {
    h3 {
      font-size: 22px;
    }
  }

  .footer-area {
    padding-top: 80px;
  }
  .single-footer-widget {
    h3 {
      font-size: 22px;
    }
  }
  .copyright-area {
    margin-top: 50px;
  }
}

@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {
  p {
    font-size: 14px;
  }
  .section-title {
    max-width: 565px;

    span {
      font-size: 17px;
    }
    h2 {
      font-size: 35px;
    }
  }

  .main-banner {
    background: {
      image: unset;
      color: $main-color;
    }
    padding: {
      top: 130px;
      bottom: 130px;
    }

    clip-path: none;
    border-bottom-left-radius: 0;
    background: linear-gradient(90deg, #4303b8, #7f1cbd, #49d1f5);
  }
  .main-banner-content {
    h1 {
      font-size: 60px;
    }
    .price {
      font-size: 60px;
    }
    p {
      font-size: 15px;
    }
  }
  .banner-image {
    img {
      max-width: 105%;
    }
  }

  .coverage-area-content {
    span {
      font-size: 17px;
    }
    h2 {
      font-size: 35px;
    }
  }
  .coverage-area-map {
    img {
      max-width: 105%;
    }
  }

  .single-features-box {
    h3 {
      font-size: 22px;
    }
  }

  .about-content {
    span {
      font-size: 17px;
    }
    h2 {
      font-size: 35px;
    }
  }

  .single-services-box {
    h3 {
      font-size: 22px;
    }
  }

  .single-pricing-table {
    padding: 25px;

    .pricing-header {
      span {
        font-size: 14px;
      }
      h3 {
        font-size: 22px;
      }
    }
  }

  .pricing-about-content {
    span {
      font-size: 17px;
    }
    h2 {
      font-size: 35px;
    }
  }
  .single-footer-widget {
    h3 {
      font-size: 22px;
    }
  }

  .single-box {
    h3 {
      font-size: 22px;
    }
  }

  .services-box {
    h3 {
      font-size: 22px;
    }
  }

  .single-contact-info {
    h3 {
      font-size: 22px;
    }
  }
}

@media only #{$media} and ($feature_min : $value_six) and ($feature_max : $value_seven) {
  .banner-image {
    img {
      max-width: 108%;
    }
  }

  .coverage-area-map {
    img {
      max-width: 105%;
    }
  }
}
