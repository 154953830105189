/* You can add global styles to this file, and also import other style files */
@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';

@import 'stylings/variables.scss';

.swiper-button-next,
.swiper-button-prev {
  color: $optional-color !important;
}

body {
  padding: 0;
  margin: 0;
  font: {
    family: $font-family;
    size: $font-size;
  }
}

img {
  max-width: 100%;
}

.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
p {
  line-height: 1.9;
  color: $paragraph-color;
  margin-bottom: 15px;
  font: {
    family: $optional-font-family;
    size: 15px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
a {
  display: block;
  color: $black-color;
  text-decoration: none;
  transition: $transition;

  &:hover {
    color: $optional-color;
    text-decoration: none;
  }
}

.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}
/*btn*/
.btn {
  border: none;
  padding: 15px 30px;
  line-height: initial;
  border-radius: 30px;
  transition: $transition;
  position: relative;
  z-index: 1;
  font: {
    family: $optional-font-family;
    size: 15px;
    weight: 700;
  }
  &.disabled,
  &:disabled {
    opacity: 1;
  }
}
.btn-primary {
  color: $white-color;
  background-color: $main-color;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    border-radius: 30px 0 0 30px;
    z-index: -1;
    background: $optional-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    border-radius: 0 30px 30px 0;
    z-index: -1;
    background: $optional-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &.disabled,
  &:disabled {
    color: $black-color;
    background-color: gray;
    cursor: none;
  }
  &:hover,
  &:focus {
    color: $white-color !important;
    background-color: $main-color !important;
    box-shadow: unset !important;

    &::before {
      width: 60%;
      opacity: 1;
      visibility: visible;
    }
    &::after {
      width: 60%;
      opacity: 1;
      visibility: visible;
    }
  }
}

.btn-secondary {
  color: $white-color;
  background-color: $secondary-color;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    border-radius: 30px 0 0 30px;
    z-index: -1;
    background: $optional-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 100%;
    border-radius: 0 30px 30px 0;
    z-index: -1;
    background: $optional-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &.disabled,
  &:disabled {
    color: $black-color;
    background-color: $main-color;
  }
  &:hover,
  &:focus {
    color: $white-color !important;
    background-color: $main-color !important;
    box-shadow: unset !important;

    &::before {
      width: 60%;
      opacity: 1;
      visibility: visible;
    }
    &::after {
      width: 60%;
      opacity: 1;
      visibility: visible;
    }
  }
}
/*section-title*/
.section-title {
  text-align: center;
  max-width: 700px;
  margin: {
    bottom: 60px;
    left: auto;
    right: auto;
  }
  span {
    display: inline-block;
    color: $white-color;
    font-size: 19px;
    margin-bottom: 5px;

    .icon {
      background-color: $optional-color;
      color: $white-color;
      width: 30px;
      height: 30px;
      border-radius: 7px;
      font-size: 15px;
      line-height: 30px;
      display: inline-block;
      margin-top: 0;

      &::before {
        font-size: 12px;
      }
    }
    span {
      display: block;
      margin: {
        bottom: 0;
        top: 13px;
      }
    }
  }
  h2 {
    margin-bottom: 0;
    font: {
      size: 38px;
      weight: 700;
    }
  }
  p {
    margin: {
      bottom: 0;
      top: 12px;
    }
  }
}

/*================================================
About Area CSS
=================================================*/
.about-content {
  span {
    display: inline-block;
    color: $main-color;
    font-size: 18px;
    margin-bottom: 15px;

    i {
      background-color: $optional-color;
      color: $white-color;
      width: 30px;
      height: 30px;
      border-radius: 7px;
      line-height: 30px;
      text-align: center;
      display: inline-block;
      margin-right: 4px;

      &::before {
        font-size: 12px;
      }
    }
  }
  h2 {
    margin-bottom: 15px;
    font: {
      size: 38px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-top: 5px;
  }
}
.about-image {
  text-align: center;
}
.about-main-image {
  position: relative;
  text-align: center;
  z-index: 1;
  padding: {
    left: 20px;
    bottom: 20px;
    right: 20px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 87%;
    z-index: -1;
    background: $optional-color;
  }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;

  &.bg-image {
    background: {
      color: $dark-color;
    }
    .section-title {
      h2 {
        color: $white-color;
      }
    }
  }
}
.single-services-box {
  margin-bottom: 30px;
  text-align: center;
  transition: $transition;
  padding: 25px;
  border: 2px solid #35384a;

  .icon {
    color: $optional-color;
    margin-bottom: 10px;

    i {
      padding-bottom: 10px;
      padding-top: 10px;
      &::before {
        font-size: 40px;
      }
    }
  }
  h3 {
    margin-bottom: 10px;
    color: $white-color;
    font: {
      size: 24px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 0;
    color: #d7d7d7;
  }
  .read-more-btn {
    margin-top: 15px;
    display: inline-block;
    color: $main-color;
    font-weight: 600;

    i {
      margin-left: 1px;
      transition: $transition;

      &::before {
        font-size: 13px;
      }
    }
    &:hover {
      i {
        margin-left: 5px;
      }
    }
  }
  &:hover {
    border-color: $optional-color;
  }
}
.services-box {
  text-align: center;
  margin-bottom: 30px;
  padding: 25px;
  background-color: $white-color;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.07), 0 10px 10px rgba(0, 0, 0, 0.05);
  transition: $transition;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .icon {
    color: $white-color;
    margin-bottom: 20px;
    background-color: $main-color;
    width: 75px;
    height: 75px;
    line-height: 78px;
    border-radius: 50%;
    transition: $transition;
    display: inline-block;

    i {
      &::before {
        font-size: 30px;
      }
    }
  }
  h3 {
    transition: $transition;
    margin-bottom: 10px;
    font: {
      size: 24px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 0;
    transition: $transition;
  }

  .image-box {
    img {
      position: absolute;
      z-index: -1;
      max-width: 50%;
      opacity: 0;
      visibility: hidden;
      transition: $transition;

      &:nth-child(1) {
        top: -20px;
        right: -20px;
      }
      &:nth-child(2) {
        bottom: -20px;
        left: -20px;
        transform: rotate(180deg);
      }
    }
  }
  &:hover {
    background-color: $dark-color;

    h3,
    p {
      color: $white-color;
    }

    .image-box {
      img {
        opacity: 1;
        visibility: visible;

        &:nth-child(1) {
          top: 0;
          right: 0;
        }
        &:nth-child(2) {
          bottom: 0;
          left: 0;
        }
      }
    }

    .icon {
      background-color: $optional-color;
    }
  }
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
  padding-bottom: 70px;

  &.extra-mb {
    margin-bottom: -480px;
  }
}
.single-pricing-table {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.07), 0 10px 10px rgba(0, 0, 0, 0.05);
  z-index: 1;
  background-color: $main-color;
  transition: $transition;
  padding: 45px;

  .pricing-header {
    position: relative;
    z-index: 1;
    margin-bottom: 25px;

    .icon {
      display: inline-block;
      background-color: $optional-color;
      color: $white-color;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      border-radius: 50%;
      transition: $transition;
      margin-bottom: 20px;

      i {
        &::before {
          font-size: 30px;
        }
      }
    }
    span {
      display: block;
      text-transform: uppercase;
      color: $white-color;
      margin-bottom: 3px;
    }
    h3 {
      margin-bottom: 0;
      color: $white-color;
      font: {
        size: 24px;
        weight: 700;
      }
    }
  }
  .pricing-features-list {
    position: relative;
    z-index: 1;
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li {
      color: $white-color;
      margin-bottom: 10px;

      i {
        background-color: $optional-color;
        display: inline-block;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        text-align: center;
        line-height: 22px;
        transition: $transition;

        &::before {
          font-size: 9px;
        }
      }
    }
  }
  .price {
    position: relative;
    z-index: 1;
    margin-top: 20px;
    color: $white-color;
    font: {
      size: 30px;
      weight: 700;
    }
    span {
      &:nth-child(1) {
        display: block;
        color: $white-color;
        margin-bottom: -5px;
        position: relative;
        left: 3px;
        font: {
          size: $font-size;
          weight: normal;
        }
      }
      &:nth-child(2) {
        position: relative;
        right: 0px;
        bottom: 1px;
        font: {
          size: 14px;
          weight: normal;
        }
      }
      &:nth-child(3) {
        position: relative;
        left: -5px;
        font: {
          size: $font-size;
          weight: normal;
        }
      }
    }
  }
  .view-plans-btn {
    position: relative;
    z-index: 1;
    margin-top: 25px;
    padding: 11px 30px;
    color: $white-color;
    border-radius: 30px;
    border: 2px solid white;
    font: {
      size: 15px;
      weight: 700;
      family: $optional-font-family;
    }
    &:hover {
      background-color: $optional-color !important;
      border-color: $optional-color !important;
      color: $white-color !important;
    }
  }
  &::before {
    z-index: -1;
    background: $main-color;
    width: 60%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transition: $transition;
  }
  &::after {
    z-index: -1;
    background: $main-color;
    width: 60%;
    height: 100%;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    transition: $transition;
  }
  .image-box {
    img {
      position: absolute;
      max-width: 90%;
      opacity: 0.09;
      transition: $transition;

      &:nth-child(1) {
        top: 0;
        right: 0;
        transform: rotate(180deg);
      }
      &:nth-child(2) {
        bottom: 0;
        left: 0;
      }
    }
  }
  &:hover,
  &.active {
    background-color: #21232f;

    &::before,
    &::after {
      width: 0;
    }
    .icon {
      background-color: $optional-color;
    }
    .pricing-features-list {
      li {
        i {
          background-color: #393d4b;
        }
      }
    }
    .view-plans-btn {
      background-color: $main-color;
      border-color: $main-color;
      color: $white-color;
    }
  }
}

/*================================================
Pricing About Area CSS
=================================================*/
.pricing-about-content {
  span {
    display: inline-block;
    color: $optional-color;
    font-size: 18px;
    margin-bottom: 5px;
  }
  h2 {
    margin-bottom: 13px;
    font: {
      size: 38px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-top: 5px;
  }
  .btn-primary {
    background-color: $main-color;
    color: $white-color;

    &::before,
    &::after {
      background: $optional-color;
    }
    &:hover,
    &:focus {
      color: $white-color !important;
      background-color: $optional-color !important;
    }
  }
}
.pricing-about-image {
  position: relative;
  text-align: center;
  z-index: 1;
  padding: {
    left: 20px;
    bottom: 20px;
    right: 20px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 87%;
    z-index: -1;
    background: $optional-color;
  }
}

/*================================================
Our Coverage Area CSS
=================================================*/
.our-coverage-area {
  position: relative;
  z-index: 1;
  background: {
    color: $dark-color;
  }
}
.coverage-area-content {
  span {
    display: inline-block;
    color: $optional-color;
    font-size: 18px;
    margin-bottom: 15px;

    i {
      background-color: $optional-color;
      color: $white-color;
      width: 30px;
      height: 30px;
      border-radius: 7px;
      line-height: 30px;
      text-align: center;
      display: inline-block;
      margin-right: 4px;

      &::before {
        font-size: 12px;
      }
    }
  }
  h2 {
    color: $white-color;
    margin-bottom: 15px;
    font: {
      size: 38px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 15px;
    color: $white-color;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-top: 5px;
  }
}
.coverage-area-map {
  img {
    max-width: 110%;
  }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info-box-area {
  padding-top: 70px;
}
.single-contact-info {
  text-align: center;
  margin-top: 30px;
  padding: 30px 20px;
  background-color: $white-color;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.07), 0 10px 10px rgba(0, 0, 0, 0.05);
  transition: $transition;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .icon {
    color: $white-color;
    margin-bottom: 20px;
    background-color: $main-color;
    display: inline-block;
    width: 75px;
    height: 75px;
    line-height: 78px;
    border-radius: 50%;
    transition: $transition;

    i {
      &::before {
        font-size: 30px;
      }
    }
  }
  h3 {
    transition: $transition;
    margin-bottom: 10px;
    font: {
      size: 24px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 0;
    transition: $transition;

    a {
      color: $paragraph-color;

      &:hover {
        font-weight: 700;
      }
    }
  }
  .image-box {
    img {
      position: absolute;
      z-index: -1;
      max-width: 50%;
      opacity: 0;
      visibility: hidden;
      transition: $transition;

      &:nth-child(1) {
        top: -20px;
        right: -20px;
      }
      &:nth-child(2) {
        bottom: -20px;
        left: -20px;
        transform: rotate(180deg);
      }
    }
  }
  &:hover {
    background-color: $dark-color;

    h3,
    p {
      color: $white-color;

      a {
        color: $white-color;
      }
    }
    .icon {
      color: $white-color;
      background-color: $optional-color;
    }
    .image-box {
      img {
        opacity: 0.15;
        visibility: visible;

        &:nth-child(1) {
          top: 0;
          right: 0;
        }
        &:nth-child(2) {
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}
#map {
  iframe {
    width: 100%;
    height: 500px;
    border: none;
    margin-bottom: -7px;
  }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;

  h3 {
    font: {
      size: 35px;
      weight: 700;
    }
    margin: {
      top: 30px;
      bottom: 17px;
    }
  }
  p {
    max-width: 520px;
    margin: 0 auto 20px;
  }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  background: {
    color: #20222e;
  }
  padding: {
    top: 100px;
  }
}

.single-footer-widget {
  margin-bottom: 30px;

  h3 {
    color: $white-color;
    margin-bottom: 20px;
    font: {
      size: 24px;
      weight: 700;
    }
  }
  .logo {
    a {
      display: inline-block;
    }
    p {
      color: #d7d7d7;
      font-size: 14px;
      margin-top: 18px;
    }
  }
  .newsletter-form {
    margin-top: 15px;
    position: relative;

    .newsletter-input {
      display: block;
      width: 100%;
      height: 50px;
      border: 1px solid #908482;
      background-color: transparent;
      border-radius: 30px;
      color: $white-color;
      padding-left: 15px;
      font-size: 14px;
      outline: 0;

      &::placeholder {
        color: #d7d7d7;
      }
    }
    button {
      position: absolute;
      outline: 0;
      right: 0;
      top: 0;
      height: 50px;
      width: 55px;
      background-color: $main-color;
      color: $black-color;
      border: none;
      transition: $transition;

      i {
        &::before {
          font-size: 20px;
        }
      }
      &:hover {
        color: $white-color;
        background-color: $optional-color;
      }
    }
    .validation-danger {
      color: red;
    }
    .validation-success {
      color: $white-color;
    }
  }
  .services-widget-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 9px;

      a {
        color: #d7d7d7;

        &:hover {
          color: $optional-color;
          padding-left: 5px;
        }
      }
    }
  }
  .links-widget-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 9px;

      a {
        color: #d7d7d7;

        &:hover {
          color: $optional-color;
          padding-left: 5px;
        }
      }
    }
  }
  .account-widget-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 9px;

      a {
        color: #d7d7d7;

        &:hover {
          color: $optional-color;
          padding-left: 5px;
        }
      }
    }
  }
  .widget-contact-info {
    p {
      color: #d7d7d7;

      a {
        &:hover {
          color: $optional-color;
        }
      }
      span {
        display: block;
      }
    }
  }
  .footer-contact-info {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 9px;
      color: #d7d7d7;

      a {
        color: #d7d7d7;

        &:hover {
          color: $optional-color;
        }
        i {
          color: $optional-color;
        }
      }
    }
  }
}
.copyright-area {
  border-top: 1px solid #292b38;
  text-align: center;
  margin-top: 60px;
  padding: {
    top: 30px;
    bottom: 30px;
  }
  p {
    margin-bottom: 0;
    color: #d7d7d7;

    a {
      display: inline;
      font-weight: 600;
      color: $white-color;
    }
  }
  .social {
    text-align: left;
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0;

    li {
      display: inline-block;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
      a {
        width: 35px;
        height: 35px;
        line-height: 37px;
        background-color: $black-color;
        text-align: center;
        color: $white-color;
        border-radius: 50%;
        font-size: 14px;

        &:hover {
          background-color: $optional-color;
          color: $white-color;
        }
      }
    }
  }
}

@import 'responsive';
